import styled from "styled-components";

export const ModalContainer = styled.section`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  cursor: pointer;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContent = styled.div`
  width: 600px;
  padding: 20px;
  cursor: initial;
  background-color: #fff;
`;
