import { useEffect, useState } from "react";
import { Vehicle } from "../../interfaces/vehicle";
import sorter from "../../../../common/utils/sorter";
import { getVehicles } from "../../services/vehiclesService";
import { FilterPanel, VehiclesGrid } from "./VehiclesListStyles";
import { Modal } from "../../../../common/components/Modal/Modal";
import { ListField } from "../../../../common/components/ListField/ListField";
import { VehicleListItem } from "../../components/VehiclesListItem/VehiclesListItem";
import { VehicleModalItem } from "../../components/VehiclesModalItem/VehiclesModalItem";

export function VehiclesList() {
  const [modalStatus, setModalStatus] = useState(false);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);
  const SORT_DIRECTIONS = [
    { value: "ASCENDING", label: "Ascending" },
    { value: "DESCENDING", label: "Descending" },
  ];

  useEffect(() => {
    const init = async () => {
      const _vehicles = await getVehicles();
      setVehicles(_vehicles);
    };

    init();
  }, []);

  function onVehicleSelect(vehicle: Vehicle) {
    setModalStatus(true);
    setSelectedVehicle(vehicle);
  }

  function onModalClose() {
    setModalStatus(false);
    setSelectedVehicle(null);
  }

  function renderVehicles() {
    if (!vehicles.length) {
      return null;
    }

    return vehicles.map((vehicle) => (
      <VehicleListItem
        data={vehicle}
        key={vehicle.id}
        onVehicleSelect={onVehicleSelect}
      />
    ));
  }

  function onSortingByPrice(direction: string) {
    const _vehicles = vehicles.sort((A, B) => {
      // Remove price unit and convert into a number for better sorting accuracy
      const itemA = Number(A.price.replace(/[A-Z]/g, ""));
      const itemB = Number(B.price.replace(/[A-Z]/g, ""));

      return sorter(direction, itemA, itemB);
    });

    setVehicles([..._vehicles]);
  }

  function onSortingByRange(direction: string) {
    const _vehicles = vehicles.sort((A, B) => {
      const itemA = Number(A.range.distance);
      const itemB = Number(B.range.distance);

      return sorter(direction, itemA, itemB);
    });

    setVehicles([..._vehicles]);
  }

  return (
    <section>
      <FilterPanel>
        <ListField
          id="sortByPrice"
          name="sortByPrice"
          label="Sort by price"
          data={SORT_DIRECTIONS}
          onDataSelect={(item) => onSortingByPrice(item)}
        />
        <ListField
          id="sortByRange"
          name="sortByRange"
          label="Sort by range"
          data={SORT_DIRECTIONS}
          onDataSelect={(item) => onSortingByRange(item)}
        />
      </FilterPanel>

      {modalStatus && selectedVehicle && (
        <Modal onClose={onModalClose}>
          <VehicleModalItem data={selectedVehicle} />
        </Modal>
      )}

      <VehiclesGrid>{renderVehicles()}</VehiclesGrid>
    </section>
  );
}
