import styled from "styled-components";

export const InputControl = styled.div`
  label {
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
  }

  input,
  select,
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
`;
