import "../../common/styles/global.css";
import { AppContent } from "./AppStyles";
import { Header } from "../../common/components/Header/Header";
import { VehiclesList } from "../../modules/vehicles/containers/VehiclesList/VehiclesList";

function App() {
  return (
    <main>
      <Header></Header>
      <AppContent>
        <VehiclesList />
      </AppContent>
    </main>
  );
}

export default App;
