import { GridContainer } from "../../styles/grid";
import { HeaderContainer } from "./HeaderStyles";

export function Header() {
  return (
    <HeaderContainer>
      <GridContainer>
        <h1>Vehicles App</h1>
      </GridContainer>
    </HeaderContainer>
  );
}
