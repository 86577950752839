import { Vehicle } from "../../interfaces/vehicle";
import {
  VehicleColors,
  VehicleMetaData,
  VehiclesModalItemContainer,
} from "./VehiclesModalItemStyles";

interface Props {
  data: Vehicle;
}

export function VehicleModalItem({ data }: Props) {
  function renderColors() {
    return data.colors.map((color) => <span key={color}>{color}</span>);
  }

  return (
    <VehiclesModalItemContainer>
      <img loading="lazy" src={data.photo} alt={data.model} />
      <h1>
        {data.make} {data.model}
      </h1>

      <VehicleMetaData>
        <h2>{data.price}</h2>
        <span>
          Range: {data.range.distance} {data.range.unit}
        </span>
        <div>
          <span>Colors:</span>
          <VehicleColors>{renderColors()}</VehicleColors>
        </div>
        {/* <span>{data.}</span> */}
      </VehicleMetaData>
    </VehiclesModalItemContainer>
  );
}
