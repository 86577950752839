import { FormEvent } from "react";
import { InputControl } from "../../styles/formFields";

interface Props {
  id: string;
  name: string;
  label: string;
  onDataSelect(item: string): void;
  data: { value: string; label: string }[];
}

export function ListField({ name, id, data, label, onDataSelect }: Props) {
  function renderOptions() {
    return data.map((item) => (
      <option value={item.value} key={item.value}>
        {item.label}
      </option>
    ));
  }

  function onChange(event: FormEvent) {
    const { value } = event.target as HTMLSelectElement;
    onDataSelect(value);
  }

  return (
    <InputControl data-testid="listField">
      <label htmlFor={name}>{label}</label>
      <select
        id={id}
        name={name}
        onChange={onChange}
        data-testid="listFieldOptions"
      >
        <option>Choose an option</option>
        {renderOptions()}
      </select>
    </InputControl>
  );
}
