import { Vehicle } from "./../interfaces/vehicle";

export async function getVehicles(): Promise<Vehicle[] | never> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/vehicles`);

    // To be awaited by the consumer of this function
    return response.json();
  } catch (error) {
    // Send error to a service such as Sentry
    console.error(error);
    throw new Error("An error occured trying to fetch vehicles data");
  }
}
