import styled from "styled-components";

export const VehiclesModalItemContainer = styled.article`
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  h1 {
    font-size: 18px;
  }
`;

export const VehicleColors = styled.div`
  span {
    font-size: 12px;
    padding: 2px 5px;
    margin-right: 5px;
    border-radius: 2px;
    background-color: #eee;
    text-transform: capitalize;
  }
`;

export const VehicleMetaData = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 20px;
    font-weight: var(--font-bold);
  }
`;
