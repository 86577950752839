import { ReactChild, MouseEvent } from "react";
import { ModalContainer, ModalContent } from "./ModalStyles";

interface Props {
  onClose(): void;
  children?: ReactChild | ReactChild[] | null;
}

export function Modal({ children, onClose }: Props) {
  function onCloseHandler(event: MouseEvent) {
    onClose();
  }

  return (
    <ModalContainer onClick={onCloseHandler}>
      <ModalContent onClick={(event) => event.stopPropagation()}>
        {children}
      </ModalContent>
    </ModalContainer>
  );
}
