import styled from "styled-components";

export const VehiclesGrid = styled.section`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`;

export const FilterPanel = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat(3, minmax(300px, 2fr));
`;
