import {
  VehicleImage,
  VehicleMetaData,
  VehicleContainer,
} from "./VehiclesListItemStyles";
import { Vehicle } from "../../interfaces/vehicle";

interface Props {
  data: Vehicle;
  onVehicleSelect(vehicle: Vehicle): void;
}

export function VehicleListItem({ data, onVehicleSelect }: Props) {
  return (
    <VehicleContainer>
      <div onClick={() => onVehicleSelect(data)}>
        <VehicleImage>
          <img loading="lazy" src={data.photo} alt={data.model} />
        </VehicleImage>

        <VehicleMetaData>
          <h1>{`${data.make} ${data.model}`}</h1>
          <h2>{data.price}</h2>
          <span>
            {data.range.distance} {data.range.unit} (Range)
          </span>
        </VehicleMetaData>
      </div>
    </VehicleContainer>
  );
}
