export default function sorter(
  direction: string,
  itemA: string | number,
  itemB: string | number
) {
  if (direction === "ASCENDING") {
    return itemA > itemB ? 1 : -1;
  }

  if (direction === "DESCENDING") {
    return itemA < itemB ? 1 : -1;
  }

  return 0;
}
