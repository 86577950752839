import styled from "styled-components";

export const VehicleContainer = styled.article`
  cursor: pointer;
  position: relative;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  ::after {
    left: 0;
    width: 0;
    bottom: -1px;
    content: "";
    height: 2px;
    position: absolute;
    transition: width 0.2s;
    background-color: var(--color-highlight);
  }

  &:hover:after {
    width: 100%;
  }
`;

export const VehicleImage = styled.div`
  width: 100%;
  height: 150px;
  background-color: #eee;

  img {
    width: 100%;
  }
`;

export const VehicleMetaData = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 20px;
    font-family: var(--font-bold);
  }

  span {
    font-size: 14px;
  }
`;
