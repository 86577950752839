import styled from "styled-components";

export const HeaderContainer = styled.header`
  width: 100%;
  padding: 20px 0;
  background-color: var(--color-highlight);

  h1 {
    color: #fff;
    font-family: var(--font-regular);
  }
`;
